<template lang="">
    <div>
        
    </div>
</template>
<script>
export default {
    created() {
          this.getView()
    },
    methods:{
       getView (){ this.$http.get('/ViewAccess/contactSupport')
        .then(() => { 
        })
        .catch(() => { 
        })},
    }
}
</script>
<style lang="">
    
</style>